<template>
  <collect-container>
    <el-form
      inline
      ref="form"
      :rules="rules"
      :model="form"
      label-position="top"
    >
      <el-form-item prop="contact" label="联系人姓名">
        <el-input v-model="form.contact" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="mobile" label="联系电话">
        <el-input v-model="form.mobile" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="company" label="公司名称">
        <el-input v-model="form.company" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="item" label="主营商品">
        <el-input v-model="form.item" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item prop="cloud" label="需求仓库">
        <el-cascader
          v-model="form.cloud"
          :options="regionList"
          :props="cascaderProps"
          @expand-change="handleItemChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item prop="biz_name" label="需求业务类型">
        <el-select v-model="form.biz_name">
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="您是从什么渠道了解魔筷的?"
        prop="source"
        class="learn learn-item"
      >
        <el-select
          v-model="form.source"
          placeholder="请选择"
          @change="where_learn"
        >
          <el-option
            v-for="(item, index) in optionData"
            :key="index"
            :label="item"
            :value="item"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item prop="learnInfo" v-if="visible" class="learn">
        <el-input v-model="form.learnInfo" placeholder="请输入"></el-input>
      </el-form-item>
      <div class="submit-btn" @click="submit">立即提交</div>
    </el-form>
    <collect-success-modal
      :visible="modalVisible"
      @close="closeModal"
    ></collect-success-modal>
  </collect-container>
</template>

<script>
import util from '@/js/util'
import CollectContainer from '@/components/CollectContainer.vue'
import CollectSuccessModal from '@/components/CollectSuccessModal.vue'

const mobileCheck = (rule, value, callback) => {
  if (!util.isPhoneAvailable(value)) {
    return callback(new Error('请输入正确的手机号'))
  }
  callback()
}

export default {
  components: {
    CollectContainer,
    CollectSuccessModal,
  },
  data() {
    return {
      modalVisible: false,
      types: ['魔筷', '快手', '抖音', '淘系', '京东', '拼多多'],
      form: {
        contact: '',
        mobile: '',
        company: '',
        item: '',
        cloud: '',
        biz_name: '',
        source: '',
        learnInfo: '',
      },
      cascaderProps: {
        value: 'label',
        children: 'list',
      },
      regionList: [],
      rules: {
        contact: [{ required: true, message: '请输入姓名', trigger: 'change' }],
        mobile: [
          { required: true, message: '请输入联系电话', trigger: 'change' },
          { validator: mobileCheck, trigger: 'blur' },
        ],
        company: [{ required: true, message: '请输入公司', trigger: 'change' }],
        item: [
          { required: true, message: '请输入主营商品', trigger: 'change' },
        ],
        cloud: [
          { required: true, message: '请选择需求仓库', trigger: 'change' },
        ],
        biz_name: [
          { required: true, message: '请选择需求业务类型', trigger: 'change' },
        ],
        source: [{ required: true, message: '请选择渠道', trigger: 'change' }],
        learnInfo: [
          { required: true, message: '请输入渠道', trigger: 'change' },
        ],
      },
      optionData: [
        '朋友介绍',
        '百度搜索',
        '媒体报道',
        '大型活动(行业展会、会议等)',
        '其他',
      ],
      visible: false,
    }
  },
  mounted() {
    this.initRegionData()
  },
  methods: {
    closeModal() {
      this.modalVisible = false
    },
    getRegionList(code) {
      return new Promise((resolve, reject) => {
        window.lib.api.get({
          api: 'ec/a/delivery/region/list',
          data: code ? { parent_code: code } : {},
          success: resolve,
          error: reject,
        })
      })
    },
    initRegionData() {
      this.getRegionList().then((res) => {
        if (res.data) {
          this.regionList = res.data.map((item) => ({
            code: item.code,
            label: item.name,
            list: [],
          }))
        }
      })
    },
    handleItemChange(data) {
      console.log(data)
      const items = this.regionList.filter((item) => item.label === data[0])
      if (items && items.length) {
        this.getRegionList(items[0].code).then((res) => {
          if (res.data) {
            items[0].list = res.data.map((item) => ({
              code: item.code,
              label: item.name,
            }))
          }
        })
      }
    },
    submit() {
      this.$refs.form.validate((res) => {
        if (res) {
          if (this.visible) {
            this.form.source = this.form.learnInfo
          }
          delete this.form.learnInfo
          console.log(this.form)
          const params = {
            ...this.form,
            cloud: this.form.cloud.join(''),
          }
          window.lib.api.post({
            api: 'ec/a/boss/cloud/add',
            data: params,
            success: (res) => {
              console.log(res)
              this.$refs.form.resetFields()
              this.modalVisible = true
              this.visible = false
              this.form.cloud = ''
            },
            error: (err) => {
              this.$message.error(err.msg)
            },
          })
        }
      })
    },
    where_learn(value) {
      this.visible = value == '其他' ? true : false
      this.rules.learnInfo[0].required = this.visible ? true : false
    },
  },
}
</script>

<style lang="less" scoped>
@import '../style/common.less';

/deep/ .form-container {
  min-width: 1080px !important;
}
.el-form {
  text-align: center;
  box-sizing: border-box;
  /deep/ .el-form-item {
    width: 470px;
    margin-right: 50px;
    &:nth-child(2n) {
      margin-right: 0;
    }
    .el-cascader {
      display: block;
    }
    .el-select {
      width: 100%;
    }
  }
  .learn {
    .pxToVw(margin-bottom, 20);
    width: 980px;
  }
  .learn-item {
    .pxToVw(margin-left, 80);
  }
}
</style>
